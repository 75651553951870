import { graphql, withPrefix, Link } from 'gatsby';

import * as React from 'react';
import { A } from '../components/a';
import Footer from '../components/footer';
import Navbar from '../components/navbar';
import { Location, SiteMetadata } from '../models';
import EmailPopup from '../components/email-popup';
import Seo from '../components/seo'

interface Props {
  data: {
    site: SiteMetadata;
  };
  location: Location;
}

const Portfolio = ({ data, location }: Props) => {
  return (
    <div id="main_content">
      <Seo title='Templates'/>
      <Navbar dark={true} />
      <EmailPopup/>
      <section className="page-banner">
        <div className="container">
          <div className="page-title-wrapper">
            <h1 className="page-title">Templates</h1>

            <ul className="bradcurmed">
              <li>
                <Link to="/">
                    Home
                </Link>              
              </li>
              <li>Templates</li>
            </ul>
          </div>
        </div>

        <svg
          className="circle"
          data-parallax='{"x" : -200}'
          xmlns="http://www.w3.org/2000/svg"
          width="950px"
          height="950px"
        >
          <path
            fillRule="evenodd"
            stroke="rgb(250, 112, 112)"
            strokeWidth="100px"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            opacity="0.051"
            fill="none"
            d="M450.000,50.000 C670.914,50.000 850.000,229.086 850.000,450.000 C850.000,670.914 670.914,850.000 450.000,850.000 C229.086,850.000 50.000,670.914 50.000,450.000 C50.000,229.086 229.086,50.000 450.000,50.000 Z"
          />
        </svg>

        <ul className="animate-ball">
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
        </ul>
      </section>

      <section className="portfolios">
        <div className="container">
        <span>
          Here you can download ready-to-edit complete course materials, if you don't want to start from scratch.
          Then just all you need to do is open your text editor and start creating your course content.<br></br><br></br>
        </span>
          <div className="portfolio-inner">
            <div className="pixsass-isotope wow fadeIn" data-wow-delay="0.3s">
              <div className="pixsass-portfolio-items portfolio-one column-2 port-gutters">
                {/*<div className="grid-sizer"></div>*/}



                <div className="pixsass-portfolio-item width2 grid-item pizza dolci cocktails ">
                  <div className="pixsass-isotope-grid__img">
                    <img
                      src={withPrefix('images/templates/embed_cover.png')}
                      alt="portfolio thumb"
                    />

                    <div className="portfolio-info">
                      <h3 className="portfolio-title">
                        <a
                          href={withPrefix('/templates/embed/dashboard')}
                          target="_blank"
                        >
                          Be more visual - The use of embeds
                        </a>
                      </h3>
                      <span>
                      Let's check out, how you can use embeds to make your learning material even more visual.                      </span>
                      <div className="row" style = {{ justifyContent: 'space-evenly' }}>
                        <a
                          href={withPrefix('/templates/embed/dashboard')}
                          target="_blank"
                          className="pix-btn"
                          style={{ marginTop: '20px', padding: '8px 20px'}}
                        >
                        Check it
                        </a>  
                        <a
                          href="https://download.neting.hu/review/neting/skillgo_sources/embed_source.zip"
                          download
                          className="pix-btn"
                          style={{ marginTop: '20px', padding: '8px 20px'}}
                        >
                        Download
                        </a>
                      </div>
                    </div>
                  </div>
                </div>







                <div className="pixsass-portfolio-item width2 grid-item pizza dolci cocktails ">
                  <div className="pixsass-isotope-grid__img">
                    <img
                      src={withPrefix('images/templates/hobbits_cover.jpg')}
                      alt="portfolio thumb"
                    />

                    <div className="portfolio-info">
                      <h3 className="portfolio-title">
                        <a
                          href={withPrefix('/templates/hobbits/dashboard')}
                          target="_blank"
                        >
                          Journey to Mordor
                        </a>
                      </h3>
                      <span>
                      Check out this lesson that shows you the potential to assist in the creation of e-learning content and how to combine this with the essential power of Skillgo.
                      </span>
                      <div className="row" style = {{ justifyContent: 'space-evenly' }}>
                        <a
                          href={withPrefix('/templates/hobbits/dashboard')}
                          target="_blank"
                          className="pix-btn"
                          style={{ marginTop: '20px', padding: '8px 20px'}}
                        >
                        Check it
                        </a>  
                        <a
                          href="https://download.neting.hu/review/neting/skillgo_sources/hobbits_source.zip"
                          download
                          className="pix-btn"
                          style={{ marginTop: '20px', padding: '8px 20px'}}
                        >
                        Download
                        </a>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="pixsass-portfolio-item width2 grid-item pizza dolci cocktails ">
                  <div className="pixsass-isotope-grid__img">
                    <img
                      src={withPrefix('images/templates/recycle_cover.jpg')}
                      alt="portfolio thumb"
                    />

                    <div className="portfolio-info">
                      <h3 className="portfolio-title">
                        <a
                          href={withPrefix('/templates/recycling/dashboard')}
                          target="_blank"
                        >
                          Importance of Recycling
                        </a>
                      </h3>
                      <span>
                      This course is about recycling, an important step towards a greener world. To develop it, we used Skillgo and the strength of artificial intelligence.                       </span>
                      <div className="row" style = {{ justifyContent: 'space-evenly' }}>
                        <a
                          href={withPrefix('/templates/recycling/dashboard')}
                          target="_blank"
                          className="pix-btn"
                          style={{ marginTop: '20px', padding: '8px 20px'}}
                        >
                        Check it
                        </a>  
                        <a
                          href="https://download.neting.hu/review/neting/skillgo_sources/recycling_source.zip"
                          download
                          className="pix-btn"
                          style={{ marginTop: '20px', padding: '8px 20px'}}
                        >
                        Download
                        </a>
                      </div>
                    </div>
                  </div>
                </div>




                <div className="pixsass-portfolio-item width2 grid-item pizza dolci cocktails ">
                  <div className="pixsass-isotope-grid__img">
                    <img
                      src={withPrefix('images/templates/rabbit_cover560x500.jpg')}
                      alt="portfolio thumb"
                    />

                    <div className="portfolio-info">
                      <h3 className="portfolio-title">
                        <a
                          href={withPrefix('/templates/easter/dashboard')}
                          target="_blank"
                        >
                          Easter egg
                        </a>
                      </h3>
                      <span>
                        The Skillgo and ChatGPT collaboration on the Easter course demonstrates the immense potential 
                        of AI in enhancing the eLearning landscape. 
                      </span>
                      <div className="row" style = {{ justifyContent: 'space-evenly' }}>
                        <a
                          href={withPrefix('/templates/easter/dashboard')}
                          target="_blank"
                          className="pix-btn"
                          style={{ marginTop: '20px', padding: '8px 20px'}}
                        >
                        Check it
                        </a>  
                        <a
                          href="https://download.neting.hu/review/neting/skillgo_sources/easter_source.zip"
                          download
                          className="pix-btn"
                          style={{ marginTop: '20px', padding: '8px 20px'}}
                        >
                        Download
                        </a>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="pixsass-portfolio-item width2 grid-item pizza dolci cocktails ">
                  <div className="pixsass-isotope-grid__img">
                    <img
                      src={withPrefix('images/templates/access_cover560x500.png')}
                      alt="portfolio thumb"
                    />

                    <div className="portfolio-info">
                      <h3 className="portfolio-title">
                        <a
                          href={withPrefix('/templates/accessibility/dashboard')}
                          target="_blank"
                        >
                          Introduction to Accessible E-Learning
                        </a>
                      </h3>
                      <span>
                        This course is intended for anyone involved in designing and developing
                        e-learning solutions.
                      </span>
                      <div className="row" style = {{ justifyContent: 'space-evenly' }}>
                        <a
                          href={withPrefix('/templates/accessibility/dashboard')}
                          target="_blank"
                          className="pix-btn"
                          style={{ marginTop: '20px', padding: '8px 20px'}}
                        >
                        Check it
                        </a>  
                        <a
                          href="https://download.neting.hu/review/neting/skillgo_sources/access_source.zip"
                          download
                          className="pix-btn"
                          style={{ marginTop: '20px', padding: '8px 20px'}}
                        >
                        Download
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pixsass-portfolio-item width-large grid-item pizza dolci cocktails ">
                  <div className="pixsass-isotope-grid__img">
                    <img
                      src={withPrefix('images/templates/hamster_cover560x500.jpg')}
                      alt="portfolio thumb"
                    />

                    <div className="portfolio-info">
                      <h3 className="portfolio-title">
                        <a href={withPrefix('/templates/hamsters/dashboard')} target="_blank">
                          Hamsters
                        </a>
 
                      </h3>
                      <span>
                        This course contains the most commonly used static and interactive blocks
                        available in Skillgo.
                      </span>
                      <div className="row" style = {{ justifyContent: 'space-evenly' }}>
                        <a
                          href={withPrefix('/templates/hamsters/dashboard')}
                          target="_blank"
                          className="pix-btn"
                          style={{ marginTop: '20px', padding: '8px 20px' }}
                        >
                        Check it
                        </a>
                        <a
                          href="https://download.neting.hu/review/neting/skillgo_sources/hamster_source.zip"
                          download
                          className="pix-btn"
                          style={{ marginTop: '20px', padding: '8px 20px' }}
                        >
                        Download
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
               

                {/* <div className="pixsass-portfolio-item width-sm-large grid-item pizza dolci cocktails ">
                  <div className="pixsass-isotope-grid__img">
                    <img
                      src={withPrefix('images/templates/manual_cover560x500.jpg')}
                      alt="portfolio thumb"
                    />

                    <div className="portfolio-info">
                      <h3 className="portfolio-title">
                        <a href="" target="_blank">
                          Creating Skillgo Courses - Users’ Guide
                        </a>
                      </h3>
                      <span>Coming Soon</span>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Portfolio;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
